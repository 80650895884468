<template>
  <ModalBase ref="modalBase" size="medium">
    <template #header>
      クレジットカードの変更
    </template>
    <template #body>
      <FormItem>
        <template #title>
          <FormLabel>クレジットカード</FormLabel>
        </template>
        <template #input>
          <FormRadioGroup>
            <div class="radio-wrapper" v-for="cardData of cardDataList">
              <FormRadio
                  :index="cardData.id"
                  v-model="form.payJpCardId"
                  :id="cardData.id"
                  :label="`**** **** **** ${cardData.last4} ${cardData.name}  ${cardData.expMonth}/${cardData.expYear}`"
              />
              <div v-if="errorPayJpCardId === cardData.id" class="badge ieError">ERROR</div>
              <div v-else-if="payJpCardId === cardData.id" class="badge selected">使用中</div>
            </div>
          </FormRadioGroup>
        </template>
      </FormItem>
      <div ref="payjpArea"></div>
    </template>
    <template #footer>
      <ButtonBase @click="close()" variant="normal" size="inline">キャンセル</ButtonBase>
      <ButtonBase @click="onUpdate()" variant="primary" :disabled="!form.payJpCardId">更新</ButtonBase>
    </template>
  </ModalBase>
</template>


<script>
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import ModalBase from '../modal/ModalBase'
  import ButtonBase from '../button/ButtonBase'
  import FormItem from '../form/FormItem'
  import FormLabel from '../form/FormLabel'
  import FormRadioGroup from '../form/FormRadioGroup'
  import FormRadio from '../form/FormRadio'
  import {CreatePayjpCard, PayjpCustomer} from "../../../graphqls/Payjp";
  import {
    UpdateSubscriptionOrderSummaryCard
  } from "../../../graphqls/SubscriptionOrderSummaries";
  import {config} from "../../../config/config";

  export default {
    name: 'ModalCreditCard',
    mixins: [GlobalMixin],
    components: {
      ModalBase,
      ButtonBase,
      FormItem,
      FormLabel,
      FormRadioGroup,
      FormRadio,
    },
    props: {
      subscriptionOrderSummaryId: {
        type: String,
        default: ""
      },
      payJpCardId: {
        type: String,
        default: ""
      },
      errorPayJpCardId: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        form: {
          id: '',
          payJpCardId: '',
        },
        payjpCustomer: {
          email: '',
          cards: {
            data: []
          },
          usingPayJpCardIds: []
        },
      }
    },
    computed: {
      cardDataList() {
        return this.payjpCustomer.cards.data
      },
      customer() {
        return this.payjpCustomer
      }
    },

    methods: {
      async open(e) {
        this.$refs.modalBase.open()
        this.$emit('open', e)
        await this.getPayjpCustomer();
        for (const card of this.cardDataList) {
          if (card.id === this.payJpCardId) {
            this.form.payJpCardId = card.id
            break;
          }
        }
        this.appendPayJpScriptTag();
        // appendPayJpScriptTagでscriptタグをappendとpayjpのscript実行後に処理したい
        window.onTokenCreated = this.onTokenCreated;
        window.onTokenFailed = this.onTokenFailed;
      },
      close(e) {
        this.$refs.modalBase.close()
        this.$emit('close', e)
      },
      disabledUpdateCreditButton() {
        return !this.selectPayJpCardId ||
            this.modalSubscriptionOrderSummary.payJpCard.id === this.selectPayJpCardId ||
            (this.modalSubscriptionOrderSummary.latestSubscriptionOrder.isFixDeliveryOn === 'true' && !this.nextDeliveryOn)
      },
      onUpdate() {
        this.$emit('onUpdate', this.form.payJpCardId )
      },
      async getPayjpCustomer() {
        this.loadingStart();
        const { data } = await this.$apollo
            .query({
              query: PayjpCustomer,
              client: 'apiClient'
            })
            .catch(error => {
              this.loadingStop()
              return;
            });
        this.payjpCustomer = data.payjpCustomer
        this.loadingStop();
      },
      appendPayJpScriptTag () {
        const attrs = {
          //API公開鍵 情報を入力
          'data-key':  config.pay_jp_public_key,
          'id': 'payjp-script',
          'src': 'https://checkout.pay.jp/',
          'class': 'payjp-button',
          'data-partial': true,//入力後にtokenを作成し、windowを閉じる
          'data-text': '新しいカードを登録',
          'data-submit-text': 'クレジットカード登録',
          'data-lang': 'ja',
          'data-on-created': 'onTokenCreated',
          'data-on-failed': 'onTokenFailed',
          'data-name-placeholder': 'TABERUTO KURASUTO',
          'data-payjp-extra-attribute-email': this.customer.email,
          'data-payjp-three-d-secure': 'true',
          'data-payjp-three-d-secure-workflow': 'subwindow',
        };
        this.scriptEl = document.createElement('script');
        for(let key in attrs) {
          this.scriptEl.setAttribute(key, attrs[key])
        }
        this.$refs.payjpArea.appendChild(this.scriptEl);
      },
      onTokenCreated () {
        let token = document.getElementsByName('payjp-token')[0].value;
        this.createPayjpCard(token)

        // let dataform = new FormData();
        //
        // //undefinedならテキストで undefined のまま送信
        // dataform.append('id',this.$root.user.id);
        // dataform.append('token',token);
        //
        // axios.post('/payjp/tudokakin/', dataform).then(e => {
        //   console.log("成功");
        // }).catch((error) => {
        //   console.log("エラー");
        // });

      },
      onTokenFailed (res) {
        // 処理
      },
      async createPayjpCard(token) {
        this.loadingStart();
        const { data } = await this.$apollo
            .mutate({
              mutation: CreatePayjpCard,
              // Parameters
              variables: {
                input: {
                  card: token,
                  default: true
                }
              },
              client: 'apiClient'
            })
            .catch(error => {
              console.log(error.message)
            });
        const errors = data.createPayjpCard.errors
        if (errors.length > 0) {
          this.errors = errors
        } else {
          this.payjpCustomer = data.createPayjpCard.customer
          this.setToast({ toast: { type: 'success', messages: ['カードの登録が完了しました'] }})
        }
        this.loadingStop();
        this.resetPayjp();
      },

      resetPayjp() {
        document.getElementsByName('payjp-token')[0].value = ""
        document.querySelector("#payjp_checkout_box input[type='button']").value = '新しいカードを登録'
        document.querySelector("#payjp_checkout_box input[type='button']").classList.remove('has-token');
      }

    },
    mounted() {
    },
    beforeDestroy () {
      const payjpCheckoutIframeEl = document.getElementById('payjp-checkout-iframe');
      payjpCheckoutIframeEl.parentElement.remove();
      this.$refs.payjpArea.removeChild(this.scriptEl);
      window.PayjpCheckout = null;
      window.onTokenCreated = null;
      window.onTokenFailed = null;
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

  .modal-base {
    .body {
      section {
        .heading {
          font-family: $font-family-sans;
          font-weight: $font-weight-bold;
          font-style: $font-style-not-italic;
          @include font-size(sm);
          line-height: $line-height-snug;
          color: $black-400;
          @include margin-bottom(md);
        }
        .info {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          > .quantity {
            @include margin-right(sm);
          }
          > .price {
            font-weight: 700;
          }
          > .unit {
            @include font-size(xxs)
          }
        }
      }
      > a {
        color: $olive-400;
        text-decoration-color: $olive-400;       
        text-decoration-line: underline;
      }
      .radio-wrapper {
        display: flex;

        .badge {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 16px;
          border-radius: 3px;
          @include margin-left(xxxs);

          font-family: $font-family-sans;
          font-weight: $font-weight-regular;
          font-style: $font-style-not-italic;
          @include font-size(xxs);
          line-height: $line-height-none;

        }
        .ieError {
          width: 50px;
          background: $red-300;
          color: $white;
        }

        .selected {
          width: 50px;
          background: $olive-300;
          color: $white;
        }
      }
    }
  }
</style>
