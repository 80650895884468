<template>
  <div class="container">
    <FlashMessages class="mb-xs" />
    <h1>注文履歴</h1>
    <p>お客様の声が生産者さんの力になります。レビューを書くとポイントをプレゼント！</p>
    <div class="orders" v-if="orderItems.length > 0">
      <div class="order-wrap" v-for="order in orderItems">
        <div class="order-info">
          <div class="shop">
            <a class="logo" :href="`/shop/${order.adminShop.code}/`">
              <img
                  v-if="order.adminShop.publishedStatus === 'published'"
                  :alt="`${order.adminShop.name}ロゴ`"
                  :src="order.adminShop.logoImageSet.xsSqImage"
              />
              <img
                  v-else
                  :alt="`${order.adminShop.name}ロゴ`"
                  src="https://cdn.taberutokurasuto.com/image/common/noimage_100x100.gif"
              />
            </a>
            <div class="shop-name">
              <a :href="`/shop/${order.adminShop.code}/`">{{ order.adminShop.name }}</a>
              <span class="message" v-if="order.adminShop.publishedStatus !== 'published'">
                閉店したショップです
              </span>
            </div>
          </div>
          <div class="order-status">
            <div class="order-status-details">
              <div class="item">
                <span>注文日</span>
                <span>{{ order.formatOrderedOn }}</span>
              </div>
              <div class="item">
                <span>注文番号</span>
                <span>{{ order.code }}</span>
              </div>
              <div class="item">
                <span>注文状況</span>
                <span :class="order.currentOrderSummary.orderStatus">
                  {{ order.currentOrderSummary.orderStatusLabel }}
                </span>
              </div>
            </div>
            <div class="receipt" v-if="order.currentOrderSummary.orderStatus === 'complete'">
              <ButtonBase
                  variant="normal"
                  size="inline"
                  @click="openInvoiceModal(order.id)"
              >
                領収書(PDF)
              </ButtonBase>
            </div>
          </div>
        </div>
        <div class="order-details">
          <div class="column">
            <div class="products">
              <div class="product-info" v-for="orderItem in order.currentOrderItems">
                <div class="product-thumbnail">
                  <a :href="orderItem.itemGroup.frontUrl">
                    <img :alt="orderItem.name" :src="itemImage(orderItem)" />
                  </a>
                </div>
                <div class="product-info-wrap">
                  <div class="product-info-detail">
                    <a :href="orderItem.itemGroup.frontUrl">
                      <span>{{ orderItem.name }}</span>
                      <span>{{ orderItem.item.size }}</span>
                    </a>
                    <div class="price">
                      <p>
                        {{ orderItem.priceInTaxWithCurrency }}
                        {{ order.currentOrderPayment.isAddTax ? '(税込)' : '' }}
                      </p>
                    </div>
                    <div class="state" v-if="orderItem.isPreSale">
                      <p>予約商品</p>
                    </div>
                    <div class="state" v-if="order.subscriptionOrderId">
                      <p>定期購入</p>
                    </div>
                    <div class="quantity">
                      <p>数量：{{ orderItem.quantity }}点</p>
                    </div>
                  </div>
                  <GiftDetail :order-item="orderItem" />
                  <div class="button-area">
                    <div class="button">
                      <ButtonBase
                          variant="primary"
                          :disabled="disabledReviewButton(order.currentOrderSummary) || isDraftItemGroup(order, orderItem)"
                          size="small"
                          @click="goReview(orderItem.id)"
                      >
                        {{ reviewButtonLabel(orderItem) }}
                      </ButtonBase>
                      <ButtonBase
                          variant="primary"
                          size="small"
                          :href="orderItem.itemGroup.frontUrl"
                          :disabled="isDraftItemGroup(order, orderItem)"
                      >
                        もう一度購入
                      </ButtonBase>
                    </div>
                    <p v-if="showReviewMessage(order.currentOrderSummary)">
                      出荷完了後、レビューを書くことができます
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <OrderDetail :order="order" @updateCreditCard="updateCreditCard" />
          </div>
        </div>
      </div>
    </div>
    <div class="pager-wrap" v-if="orderItems.length > 0">
      <Pager @change="changePage" :custom-page-info="pageInfo" />
    </div>
    <AlertBase v-if="orderItems.length === 0" type="info">
      <p>注文履歴はありません</p>
    </AlertBase>
    <div class="text-center my-lg">
      <ButtonBase variant="normal" href="/mypage/">マイページへ戻る</ButtonBase>
    </div>
    <ModalBase ref="modalReceipt" size="medium">
      <template #header>法人名・団体名（宛名）</template>
      <template #body>
        <section />
        <FormBlock>
          <FormBody>
            <FormGroup>
              <FormItem>
                <FormLabel>法人名・団体名（宛名）</FormLabel>
                <FormTextfield
                    v-model="form.corporateName"
                    placeholder="株式会社XXXXX"
                    :isError="'corporateName' in validationErrorMessage"
                />
                <FormMessage
                    :isError="'corporateName' in validationErrorMessage"
                >
                  {{ validationErrorMessage.corporateName }}
                </FormMessage>
              </FormItem>
            </FormGroup>
          </FormBody>
        </FormBlock>
      </template>
      <template #footer>
        <ButtonBase
            @click="$refs.modalReceipt.close()"
            variant="normal"
            size="inline"
        >
          キャンセル
        </ButtonBase>
        <ButtonBase @click="downloadReceipt()" variant="primary">
          ダウンロード
        </ButtonBase>
      </template>
    </ModalBase>
  </div>
</template>

<script>
import GlobalMixin from '../../mixin/GlobalMixin'
import ButtonBase from '../../components/v1/button/ButtonBase'
import AlertBase from "../../components/v1/alert/AlertBase";
import Pager from "../../components/v1/pager/Pager";
import OrderDetail from "../../components/v1/purchasehistory/OrderDetail";
import FormGroup from "../../components/form/FormGroup";
import FormItem from "../../components/form/FormItem";
import FormLabel from "../../components/form/FormLabel";
import FormTextfield from "../../components/form/FormTextfield";
import FormMessage from "../../components/form/FormMessage";
import ModalBase from "../../components/v1/modal/ModalBase";
import FormBlock from "../../components/form/FormBlock";
import FormBody from "../../components/form/FormBody";
import {GetPurchaseHistories, CreateReceiptPdfUrl} from "../../graphqls/Orders";
import {GetUserCorporateNameForApi} from "../../graphqls/Users";
import GiftDetail from "../../components/v1/purchasehistory/GiftDetail";
import FlashMessages from "../../components/v1/alert/FlashMessages";
import ButtonBlock from "../../components/button/ButtonBlock.vue";
import Terms from "../../components/v1/terms/Terms.vue";
import {UpdateSubscriptionOrderSummaryCard} from "../../graphqls/SubscriptionOrderSummaries";

export default {
  name: "purchase-history",
  mixins: [GlobalMixin],
  components: {
    Terms,
    ButtonBlock,
    GiftDetail,
    FormBody,
    FormBlock,
    ModalBase,
    FormMessage,
    FormTextfield,
    FormLabel,
    FormItem,
    FormGroup,
    OrderDetail,
    Pager,
    ButtonBase,
    AlertBase,
    FlashMessages,
  },
  data() {
    return {
      showDetail: false,
      arrowIcon: require('../../../../assets/images/icon/arrow-up.svg'),
      orders: null,
      pageInfo: {
        currentPage:  Number(this.$route.query.page || 1),
        totalCount: 0,
        perPage: 5,
      },
      selectedOrderId: "",
      form: {
        orderId: "",
        corporateName: ""
      },
      creditForm: {
        orderId: "",
        payJpCardId: ""
      },
      showGift: false,
      errors: []
    };
  }, 
  mounted() {
    this.getPurchaseHistories()
    this.getUserCorporateNameForApi()
    this.setBreadcrumbs({ breadcrumbs: [
       {
         path: '/',
         name: 'TOP',
       },
       {
         path: '/mypage/',
         name: 'マイページ',
       },
       {
         path: '/mypage/purchasehistory/',
         name: '注文履歴',
       },
     ]})
  },
  computed: {
    orderItems() {
      return this.orders?.items || [];
    },
    validationErrorMessage() {
      let result = {}
      this.errors.forEach(error => {
        result[error.attribute] = error.message
      });
      return result
    }
  },
  methods: {
    async getUserCorporateNameForApi() {
      this.loadingStart()
      const { data } = await this.$apollo
          .query({
            query: GetUserCorporateNameForApi,
            client: 'apiClient'
          })
      this.form.corporateName = data.user.corporateName
    },
    async getPurchaseHistories() {
      this.loadingStart()
      const { data } = await this.$apollo
      .query({
        query: GetPurchaseHistories,
        variables: {
          ...this.pageInfo,
          search: JSON.stringify({
            code_eq: this.$route.query.code,
            credit_error_not_null: this.$route.query.credit_error,
          })
        },
        client: 'apiClient'
      })
      this.orders = data.orders
      this.pageInfo =  this.orders.pageInfo
      this.$scrollTo("body")
      this.loadingStop()
    },
    async downloadReceipt() {
      try {
        this.loadingStart();
        this.errors = [];
        const {data} = await this.$apollo
            .mutate({
              mutation: CreateReceiptPdfUrl,
              variables: {
                input: this.form
              },
              client: 'apiClient'
            })
        const errors = data.createReceiptPdfUrl.errors;
        if (errors) {
          this.errors = errors
          return
        }

        const link = document.createElement('a')
        // link.download = 'result.csv'
        link.href = data.createReceiptPdfUrl.downloadUrl
        link.click()
        this.$refs.modalReceipt.close()
      } catch (error) {
        // location.href="/"
        return;
      } finally {
        this.loadingStop();
      }
    },
    changePage(newPage) {
      this.pageInfo.currentPage = newPage
      this.$router.push({name: 'mypage-purchase-history', query: {page: newPage}})
      this.getPurchaseHistories()
    },
    disabledReviewButton(orderSummary) {
      return orderSummary.orderStatus !== 'complete'
    },
    isDraftItemGroup(order, orderItem) {
      return order.adminShop.publishedStatus !== 'published' || orderItem.itemGroup.publishedStatus !== 'published'
    },
    goReview(orderItemId) {
      this.$router.push({ name: 'mypage-reviews-show', params: { id: orderItemId } })
    },
    showReviewMessage(orderSummary) {
      return orderSummary.orderStatus === 'accept' || orderSummary.orderStatus === 'confirmed'
    },
    reviewButtonLabel(orderItem) {
      return  !orderItem?.review?.postedAt ? 'レビューを書く' : 'レビューを編集'
    },
    openInvoiceModal(orderId) {
      this.form.orderId = orderId
      this.$refs.modalReceipt.open()
    },
    itemImage(orderItem) {
      if (!!orderItem.item.itemImageUrl) {
        return orderItem.item.itemImageSets.xsSqImage
      }
      return orderItem.itemGroup?.listImageOrItemGroupImageSet?.xsSqImage
    },
    async updateCreditCard() {
      await this.getPurchaseHistories();
      this.setToast({ toast: { type: 'success', messages: ['クレジットカードを更新しました'] }})
    }
  },
}
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";
  .container {
    font-family: $font-family-sans;
    font-weight: $font-weight-regular;
    font-style: $font-style-not-italic;
    font-size: 14px;
    color: $black-400;
    line-height: $line-height-tight;
    > h1 {
      @include margin-bottom(xxs);
      font-family: $font-family-serif;
      font-weight: $font-weight-regular;
      text-align: left;
      @include font-size(md);
    }
    > p {
      @include margin-bottom(xxs);
    }
    > .orders {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-self: stretch;      
      > .order-wrap {
        border: 1px solid $gray-300;
        > .order-info {
            background-color: $gray-100;
            border-bottom: 1px solid $gray-300;
            border-left: 2px solid $olive-300;
            display: flex;
            padding: 16px;
            flex-direction: column;
            align-items: flex-end;
            flex: 1 0 0;  
            @media (max-width: 768px) {            
              > .order-info {
                gap: 8px;
              }
            }
            > .shop {
              display: flex;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch;
              @include margin-bottom(xxxs);  
              > .logo {
                width: 18px;
                height: 18px;  
                > img {
                width: 18px;
                height: 18px;
                }
              }
              > .shop-name {
                display:flex;
                flex-flow: column;
                > a {
                  text-decoration: none;
                  font-weight: $font-weight-bold;
                  color: $black-400;
                  font-size: 16px;
                  &:hover {
                    color: $olive-400;
                  }
                }
                > .message {
                  color: $black-200;
                  font-size: 12px;
                }
              }
            }
            > .order-status {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              align-self: stretch;
              position:relative;
              
              > .order-status-details {
                display: flex;
                gap: 16px;
                > .item {
                  > span:first-child {
                      @include margin-right(xxs);
                      font-weight: $font-weight-bold;
                    }  
                  > .accept,
                    .confirmed,
                    .complete {
                    color: $red-300;
                  }
                }                
              }
              @media (max-width: 768px) {            
                > .order-status-details {
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 4px;
                }
              }
            }
          }
        > .order-details {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            > .column {
              width: 100%;
              > .inquiry {
                  padding: 16px;
                > .button-base {
                  width: 100%;
                  + .button-base {
                    @include margin-top(xs);
                  }
                }
              }      
              > .products {
                display: flex;
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                align-self: stretch;          
                > .product-info {
                  display: flex;
                  align-items: flex-start;
                  gap: 8px;
                  align-self: stretch;  
                    > .product-thumbnail {
                        width: 96px;
                        height: 96px;
                        @include margin-right(xxxs);
                        cursor: pointer;  
                        > a {
                          > img {
                            width: 100%;
                          }
                        }
                    }    
                    @media (max-width: 768px) {
                      > .product-thumbnail {
                          width: 56px;
                          height: 56px;
                        @include margin-right(xxxs);  
                        > a {
                          > img {
                            width: 100%;
                          }
                        }
                      }
                    }
                  > .product-info-wrap {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 8px;
                    flex: 1 0 0;      
                    > .product-info-detail {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 4px;
                      align-self: stretch;  
                      > a {
                        text-decoration: none;
                        color: $black-400;
                        cursor: pointer;
                        > span {
                          @include margin-right(xxxs);
                        }
                      }  
                      > .price {
                        font-size: 16px;
                        font-weight: $font-weight-bold;
                      }  
                      > .state {
                        font-size: 12px;
                        color: $olive-400;
                      }
                    }
                    > .button-area {
                      > .button {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;
                      }
                      > p {
                        font-size: 12px;
                        color: $black-200;
                        @include margin-top(xxxs);
                      }
                    }
                    @media (max-width: 768px) {
                      > .button-area {
                        width: 100%;
                        > .button {
                          > button {
                            width: 100%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            > .column:first-child {
              border-bottom: 1px solid $gray-300;
            }              
            @media (min-width: 768px) {
              > .column:first-child {
                width: calc(100% - 320px);
                border-bottom: none;
              }
              > .column:nth-child(2) {
                order: 1;
                border-left: 1px solid $gray-300;
                width: 320px;
              }
            }
          }
      }  
    }
  > .pager-wrap {
      @include margin-top(md);
    }
  }
</style>