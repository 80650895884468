<template>
  <li class="item-sku-list"
      v-if="item.id"
      :class="{selected: item.id === selectedItemId}"
      :key="item.id"
      @click="selectItem(item)">
    <ButtonFavorite :position="position" :item="item" :itemGroup="itemGroup"></ButtonFavorite>
    <div class="image">
      <img :alt="item.skuName" :src="listImageOrItemGroupListImageUrl"/>
    </div>
    <div class="info">
      <div class="name">
        <span class="text">{{ item.skuName }}</span>
        <div class="coming-soon" v-if="!itemGroup.isNowOnSale || !item.isNowOnSale || item.soldOut">
          準備中
        </div>
      </div>
      <template v-if="item.isSale">
        <div class="original-price">
          {{ item.properPriceInTax | numberToCurrency }}
          <small v-if="itemGroup.adminShop.isAddTax">(税込)</small>
        </div>
        <div class="sale-name">
          {{ item.ribbonSaleName }}
        </div>
      </template>
      <div class="prices">
        <div class="price" :class="{'sale-price': item.isSale}">
          {{ item.sellingPriceInTax | numberToCurrency }}
          <small v-if="itemGroup.adminShop.isAddTax">(税込)</small>
        </div>
        <div class="postage">
          {{ ' / ' }}
          <span class="default-price free-shipping" v-if="isFreeShippingToItem(item)">
            送料無料
          </span>
          <span class="default-price" v-else>
            送料 : {{ defaultShippingPrice(item) | numberToCurrency }}
          </span>
        </div>
      </div>
      <div class="content" v-if="item.innerCapacity">
        内容量 : {{ item.innerCapacity }}
      </div>
      <div class="selling-term" v-if="showSellingTerm">
        販売期間 : {{ saleStartAt }} ~ {{ saleEndAt }}
      </div>
    </div>
  </li>
  <li class="skeleton" v-else>
    <div class="image"></div>
    <div class="info">
      <div class="line name"></div>
      <div class="line price"></div>
      <div class="line content"></div>
    </div>
  </li>
</template>

<script>
import GlobalMixin from '../../../mixin/GlobalMixin'
import ButtonFavorite from '../button/ButtonFavorite'

export default {
  name: "itemSkuList",
  mixins: [GlobalMixin],
  components: {
    ButtonFavorite
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    itemGroup: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    itemId: {
      type: String,
      default: ''
    },
    selectedItemId: {
      type: String,
      default: ''
    },
    selectedPrefectureId: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: ''
    },
  },
  computed: {
    listImageOrItemGroupListImageUrl() {
      if (this.item?.itemImageUrl) {
        return this.item?.itemImageSets?.xsSqImage
      }
      return this.itemGroup?.listImageOrItemGroupImageSet?.xsSqImage
    },
    saleStartAt() {
      if (!this.item?.dateFormatSaleStartAt && !this.itemGroup?.dateFormatSaleStartAt) return ''
      if (this.item?.dateFormatSaleStartAt && this.itemGroup?.dateFormatSaleStartAt) {
        return (new Date(this.item.saleStartAt)) < (new Date(this.itemGroup.saleStartAt)) ? this.itemGroup?.dateFormatSaleStartAt : this.item?.dateFormatSaleStartAt
      }
      return !this.item?.dateFormatSaleStartAt ? this.itemGroup?.dateFormatSaleStartAt : this.item?.dateFormatSaleStartAt
    },
    saleEndAt() {
      if (!this.item?.dateFormatSaleEndAt && !this.itemGroup?.dateFormatSaleEndAt) return ''
      if (this.item?.dateFormatSaleEndAt && this.itemGroup?.dateFormatSaleEndAt) {
        return (new Date(this.item.saleEndAt)) > (new Date(this.itemGroup.saleEndAt)) ? this.itemGroup?.dateFormatSaleEndAt : this.item?.dateFormatSaleEndAt
      }
      return !this.item?.dateFormatSaleEndAt ? this.itemGroup?.dateFormatSaleEndAt : this.item?.dateFormatSaleEndAt
    },
    showSellingTerm() {
      return this.saleStartAt || this.saleEndAt;
    }
  },
  methods: {
    selectItem() {
      this.$emit('change', this.item)
    },
    isFreeShippingToItem(item) {
      return this.defaultShippingPrice(item) === '0'
    },
    defaultShippingPrice(item) {
      if (item.itemDeliveryMethod.isPostDelivery) {
        return this.itemPrefecturePostDeliveryPriceInTax(item)
      } else if (item.itemDeliveryMethod.isCompactDelivery) {
        return this.itemPrefectureCompactPriceInTax(item)
      } else {
        return this.itemPrefectureShippingPriceInTax(item)
      }
    },
    itemPrefectureShippingPriceInTax(item) {
      return this.selectedItemShippingRegionPrices(item).priceInTax
    },
    itemPrefectureCompactPriceInTax(item) {
      return this.selectedItemShippingRegionPrices(item).compactPriceInTax
    },
    itemPrefecturePostDeliveryPriceInTax(item) {
      return this.selectedItemShippingRegionPrices(item).postDeliveryPriceInTax
    },
    selectedItemShippingRegionPrices(item) {
      return item.shippingRegionPriceList.find(i => i.prefectureId === this.selectedPrefectureId)
    },
    showCapacityOverPrice(item) {
      return this.defaultShippingPrice(item) > 0 && this.itemPrefectureShippingPriceInTax(item) > 0 && (item.itemDeliveryMethod.isPostDelivery || item.itemDeliveryMethod.isCompactDelivery)
    },

  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";

.item-sku-list {
  display: flex;
  background: $white;
  border: 1px solid $gray-200;
  cursor: pointer;
  @include padding(xxxs);
  position: relative;

  &.selected {
    border-color: $red-300;
  }

  + li {
    @include margin-top(xxxs);
  }

  > .button-favorite {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  > .image {
    width: 80px;
    min-width: 80px;
    @include margin-right(xxxs);

    > img {
      width: 100%;
    }
  }

  > .info {
    flex-grow: 1;
    color: $black-400;

    > .name {
      @include margin-bottom(xxxs);
      @include font-size(sm);

      > .text {
        font-family: $font-family-serif;
        color: $black-400;
        line-height: 20px;
      }

      > .coming-soon,
      > .sold-out {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;
        width: 44px;
        height: 20px;
        border: 1px solid $red-300;
        color: $red-300;
        line-height: 18px;
        text-align: center;
        @include margin-left(xxs);
        @include font-size(xxxs);
      }
    }

    > .original-price {
      align-items: baseline;
      font-weight: bold;
      line-height: 100%;
      text-decoration: line-through;
      @include margin-right(xxxs);
      @include font-size(sm);
    }

    > .sale-name {
      color: $olive-300;
    }

    > .prices {
      display: flex;
      align-items: center;
      @include margin-bottom(xxxs);

      > .price {
        display: flex;
        align-items: baseline;
        font-weight: bold;
        line-height: 100%;
        @include font-size(sm);

        &.sale-price {
          color: $olive-300;
        }

        > small {
          margin-right: .5em;
          font-weight: normal;
          line-height: 100%;
          @include font-size(xxs);
        }
      }

      > .postage {
        line-height: 100%;
        @include font-size(xs);

        > .default-price {
          &.free-shipping {
            font-weight: bold;
            color: $red-300;
          }
        }
      }
    }

    > .content {
      line-height: 133%;
      @include font-size(xxs);
    }

    > .selling-term {
      line-height: 133%;
      @include font-size(xxs);
    }
  }

  &.skeleton {
    > .image {
      height: 80px;
      background-color: $gray-400;
      border-radius: 5px;
    }

    > .info {
      > .line {
        height: 20px;
        background-color: $gray-400;
        border-radius: 5px;

        &.name {
          width: 30%;
        }

        &.price {
          @include margin-bottom(xxxs);
          width: 60%;
        }

        &.content {
          width: 40%;
        }
      }
    }
  }
}
</style>
