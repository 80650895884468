import gql from 'graphql-tag'

export const CreatePayjpCard = gql`
mutation($input: CreatePayjpCardInput!) {
  createPayjpCard(input: $input) {
    card {
      id
      expYear
      expMonth
      last4
      name
    }
    customer {
      id
      email
      cards {
        count
        data {
          id
          last4
          name
          expYear
          expMonth
        }
      }
      defaultCard
      usingPayJpCardIds
    }
    errors {
      model
      attribute
      message
    }
  }
}`

export const DeletePayjpCard = gql`
mutation($input: DeleteCardInput!) {
  deletePayjpCard(input: $input) {
    card {
      id
    }
    customer {
      id
      email
      cards {
        count
        data {
          id
          last4
          name
          expYear
          expMonth
        }
      }
      defaultCard
      usingPayJpCardIds
    }
    errors {
      model
      attribute
      message
    }
  }
}`


export const PayjpCustomer = gql`
query {
  payjpCustomer {
    id
    email
    cards {
      count
      data {
        id
        last4
        name
        expYear
        expMonth
      }
    }
    defaultCard
    usingPayJpCardIds
  }
}`
