<template>
  <div>
    <div class="visual-title -xs -white"
         style="background-image: url(https://cdn.taberutokurasuto.com/image/shop/item/list/sale.jpeg);">
      <div class="title">
        <i class="h1rem">
          <LogoYaneSvg/>
        </i>
        <h1 class="heading2">セール・お得商品</h1>
        <div class="heading3 curve-bottom">SALE</div>
      </div>
    </div>
    <div class="archives-description">
      <p>
        形が少し不揃いだったり、規格外のサイズだったり。でも、美味しさは変わりません。大切に育てた食材やこだわりの食品をお得にお届けします。
        さらに、初めての方にも嬉しいお試し商品や、特別価格の商品も。
        「もったいない」を「美味しい」に変えて、フードロスを減らし生産者を支えるお買い物をはじめませんか？
      </p>
    </div>

    <ItemFilterBlock>
      <DropDown
          @change="$_changeOrder"
          v-model="pageInfo.orderBy"
          :options="$_orderDropDownOptions"
      />
      <div class="stock-checkbok">
        <FormCheckbox
            @change="$_changeStockFilter"
            v-model="stockOnly"
        >
          販売中のみ表示
        </FormCheckbox>
      </div>
    </ItemFilterBlock>

    <ItemCardList>
      <div class="item lg" v-for="(itemGroup, index) in itemGroups" :key="itemGroup.id">
        <ItemCard
            :itemGroup="itemGroup"
            eventCategory="セール・お得商品一覧"
            pageContents="商品一覧"
        />
      </div>
    </ItemCardList>

    <div class="row">
      <div class="col-xs-12" v-if="itemGroups.length === 0">
        <div class="success-notice">
          <div class="text-center">商品がありません。</div>
        </div>
      </div>
      <div class="col-xs-12">
        <Pager @change="$_changePage" :custom-page-info="$_customPageInfo"/>
      </div>
    </div>
    <SectionItemArchive/>
  </div>
</template>

<script>
import {GetItemGroupsForApi} from "../../graphqls/ItemGroups";

import LogoYaneSvg from "../../components/svg/LogoYaneSvg";
import ItemList from "../../components/list/ItemList";
import GlobalMixin from "../../mixin/GlobalMixin";
import Pager from "../../components/paginate/Pager";
import DropDown from "../../components/v1/dropdown/DropDown";
import ItemListMixin from "../../mixin/ItemListMixin";
import SectionItemArchive from "../../components/v1/section/SectionItemArchive";
import FormCheckbox from "../../components/v1/form/FormCheckbox";
import ItemFilterBlock from "../../components/v1/item/ItemFilterBlock";
import ItemCardList from "../../components/v1/item/ItemCardList.vue";
import ItemCard from "../../components/v1/item/ItemCardForApi";

export default {
  name: "sale-items-index",
  mixins: [GlobalMixin, ItemListMixin],
  components: {
    ItemCard,
    ItemCardList,
    ItemFilterBlock,
    FormCheckbox,
    SectionItemArchive,
    DropDown,
    Pager,
    ItemList,
    LogoYaneSvg
  },
  props: [],
  data() {
    return {
      itemGroups: [],
      pageInfo: {
        currentPage: Number(this.$route.params.page || 1),
        totalCount: 0,
        perPage: 48,
        orderBy: this.$route.query.order || 'popular'
      },
      stockOnly: this.$route.query?.stock === 'instock',
      withinYearItem: this.$route.query.wiy === 'true',
      firstItemGroup: {ogpImageUrl: ''}
    }
  },
  computed: {
    titleBgImage() {
      if (this.firstItemGroup.ogpImageUrl) {
        return this.firstItemGroup.ogpImageUrl
      } else {
        return ''
      }
    },
    topRouterName() {
      return 'sale-items-index'
    },
    pageRouterName() {
      return 'sale-items-page-index'
    },
    stockFilter() {
      return this.stockOnly ? 'instock' : 'outstock'
    }
  },
  mounted() {
    this.fetchItemGroups()
  },
  methods: {
    async fetchItemGroups() {
      this.loadingStart();
      const {data} = await this.$apollo
          .query({
            query: GetItemGroupsForApi,
            variables: {
              saleItem: true,
              stockFilter: this.stockFilter,
              ...this.pageInfo
            },
            client: 'apiClient'
          })
          .catch(error => {
            this.loadingStop()
            return;
          });
      this.itemGroups = data.itemGroups.items;
      this.pageInfo = data.itemGroups.pageInfo
      this.loadingStop();
      this.$_gaSendViewItemList(this.itemGroups, 'shops/items/sale', this.pageInfo)
    },
    updateItemGroup(newItemGroup) {
      if (this.itemGroups[0].id === newItemGroup.id) {
        this.firstItemGroup = newItemGroup
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
