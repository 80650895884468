<template>
  <div class="detail-container">
    <div class="section-accordion" @click="open = !open">
      <p>注文詳細</p>
      <div class="svg" v-if="open">
        <img src="../../../../../assets/images/icon/arrow-up.svg" alt="arrow-up"/>
      </div>
      <div class="svg" v-else>
        <img src="../../../../../assets/images/icon/arrow-down.svg" alt="arrow-down"/>
      </div>
    </div>
    <div class="detail-wrapper" v-if="open">
      <!-- Payment Section -->
      <div class="payment">
        <h3>金額</h3>
        <div class="payment-detail">
          <span>商品小計{{ order.currentOrderPayment.isAddTax ? '(税込)' : '' }}</span>
          <span>{{ order.currentOrderPayment.itemTotalPriceInTaxWithCurrency }}</span>
        </div>
        <div class="payment-detail">
          <span>送料</span>
          <span>{{ order.currentOrderPayment.shippingPriceInTaxWithCurrency }}</span>
        </div>
        <div
            class="payment-detail"
            v-if="order.currentOrderPayment.paymentType === 'cash_on_delivery'"
        >
          <span>代引き手数料</span>
          <span>{{ order.currentOrderPayment.codFeeInTaxWithCurrency }}</span>
        </div>
        <div
            class="payment-detail"
            v-if="Number(order.currentOrderPayment.couponDiscountAmount) > 0"
        >
          <span>クーポン利用</span>
          <span>-{{ order.currentOrderPayment.couponDiscountAmountWithCurrency }}</span>
        </div>
        <div class="payment-detail" v-if="Number(order.currentOrderPayment.usePoint) > 0">
          <span>ポイント利用</span>
          <span>-{{ order.currentOrderPayment.usePointWithCurrency }}</span>
        </div>
        <div class="payment-detail">
          <span>お支払い金額{{ order.currentOrderPayment.isAddTax ? '(税込)' : '' }}</span>
          <span class="total">{{ order.currentOrderPayment.orderTotalPriceWithCurrency }}</span>
        </div>
      </div>

      <!-- Payment Methods Section -->
      <div class="payment-methods">
        <h3>お支払い方法</h3>
        <p>{{ order.currentOrderPayment.paymentTypeLabel }}</p>
      </div>

      <!-- Reserve Shipping Section -->
      <div class="reserve-shipping" v-if="isDiffShippingItem">
        <h3>発送予定日</h3>
        <p>{{ scheduledShippingStartAt }}頃から順次発送</p>
        <div class="info-accordion">
          <div class="title" @click="showDiffShipping = !showDiffShipping">
            <span class="label">発送時期が異なる商品の配送について</span>
            <div class="svg" v-if="showDiffShipping">
              <img
                  src="../../../../../assets/images/icon/arrow-drop-up.svg"
                  alt="arrow-drop-up"
              />
            </div>
            <div class="svg" v-else>
              <img
                  src="../../../../../assets/images/icon/arrow-drop-down.svg"
                  alt="arrow-drop-down"
              />
            </div>
          </div>
          <div class="details" v-if="showDiffShipping">
            <p>
              発送時期が最も遅い商品が発送されるタイミングに合わせて、全てのご注文商品を同時にお届けいたします。<br/>
              発送時期の異なる予約商品を同時にご注文の場合、送料追加となる場合がございます。その場合はショップよりご連絡させていただきます。<br/>
              また、商品によっては出荷時に確保できない場合があり、キャンセルとなる恐れがございます。その場合は、購入ショップよりご連絡させていただきます。
            </p>
          </div>
        </div>
      </div>

      <!-- Delivery Section -->
      <div class="deliver">
        <h3>お届け先</h3>
        <p>{{ order.currentOrderDelivery.name }}</p>
        <p>〒{{ order.currentOrderDelivery.zipCode }}</p>
        <p>{{ order.currentOrderDelivery.address }}</p>
        <p>{{ order.currentOrderDelivery.tel }}</p>
      </div>

      <!-- Shipping Info Section -->
      <div class="shipping">
        <h3>配送情報</h3>
        <div class="shipping-detail" v-if="order.currentOrderDelivery.displayDeliveredOn">
          <span>出荷日</span>
          <span>{{ order.currentOrderDelivery.displayDeliveredOn }}</span>
        </div>
        <div class="shipping-detail" v-if="order.currentOrderDelivery.displayDeliveryOn">
          <span>配送希望日</span>
          <span>{{ order.currentOrderDelivery.displayDeliveryOn }}</span>
        </div>
        <div class="shipping-detail" v-if="order.currentOrderDelivery.displayDeliveryTime">
          <span>配送希望時間</span>
          <span>{{ order.currentOrderDelivery.displayDeliveryTime }}</span>
        </div>
        <div class="shipping-detail">
          <span>配送方法</span>
          <span>{{ order.currentOrderDelivery.itemDeliveryMethod.name }}</span>
        </div>
        <div class="shipping-detail" v-if="order.currentOrderDelivery.deliveryCompanyLabel">
          <span>配送業者名</span>
          <span>{{ order.currentOrderDelivery.deliveryCompanyLabel }}</span>
        </div>
        <div class="shipping-detail" v-if="order.currentOrderDelivery.trackingNumber">
          <span>トラッキングナンバー</span>
          <span>{{ order.currentOrderDelivery.trackingNumber }}</span>
        </div>
      </div>

      <!-- Customer Info Section -->
      <div class="customer">
        <h3>注文者</h3>
        <p>{{ order.currentOrderContactInformation.name }}</p>
        <p>〒{{ order.currentOrderContactInformation.zipCode }}</p>
        <p>{{ order.currentOrderContactInformation.address }}</p>
        <p>{{ order.currentOrderContactInformation.tel }}</p>
      </div>

      <!-- Remarks Section -->
      <div class="remarks" v-if="order.currentOrderDelivery.memo">
        <h3>備考</h3>
        <p>{{ order.currentOrderDelivery.memo }}</p>
      </div>
    </div>
    <div class="inquiry" v-if="order.adminShop.publishedStatus === 'published'">
      <ButtonBase
          variant="normal"
          :href="`/mypage/purchasehistory/${order.id}/`"
      >
        ご注文に関するお問い合わせ
      </ButtonBase>
      <template v-if="showUpdateCreditCard">
        <ButtonBase variant="primary" @click="openCreditCardModal(order)">
          クレジットカードを変更
        </ButtonBase>
        <ModalCreditCard
            ref="ModalCreditCard"
            :payJpCardId="order.currentOrderPayment.payJpCardId"
            :errorPayJpCardId="errorPayJpCardId"
            @onUpdate="updateCreditCard"
        />
      </template>
    </div>
  </div>
</template>
<script>
import ButtonBase from "../button/ButtonBase.vue";
import ModalCreditCard from "../modal/ModalCreditCard.vue";
import GlobalMixin from "../../../mixin/GlobalMixin";
import {UpdateOrderCreditCard} from "../../../graphqls/Orders";

export default {
  name: "OrderDetail",
  mixins: [GlobalMixin],
  components: {ModalCreditCard, ButtonBase},
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data() {
    return {
      open: false,
      showDiffShipping: false
    }
  },
  computed: {
    isDiffShippingItem() {
      let result = false;
      let scheduledShippingStartAt = '';
      this.order.currentOrderItems.forEach((orderItem, index) => {
        if (index === 0) {
          scheduledShippingStartAt = orderItem.scheduledShippingStartAt
        } else {
          if (scheduledShippingStartAt !== orderItem.scheduledShippingStartAt) {
            result = true
          }
        }
      })
      return result
    },
    scheduledShippingStartAt() {
      let scheduledShippingStartAt = '';
      this.order.currentOrderItems.forEach((orderItem, index) => {
        if (orderItem.scheduledShippingStartAt) {
          if (!scheduledShippingStartAt || scheduledShippingStartAt < orderItem.scheduledShippingStartAt) {
            scheduledShippingStartAt = orderItem.formatScheduledShippingStartAt
          }
        }
      })
      return scheduledShippingStartAt;
    },
    showUpdateCreditCard() {
      const isCreditCardError = this.order.currentOrderPayment.isErrorCreditCard;
      const isOrderStatusValid = ['accept', 'confirmed'].includes(this.order.currentOrderSummary.orderStatus);
      return isCreditCardError && isOrderStatusValid;
    },
    errorPayJpCardId() {
      return this.order.currentOrderPayment.isErrorCreditCard ? this.order.currentOrderPayment.payJpCardId : '';
    }
  },
  methods: {
    openCreditCardModal() {
      this.$refs.ModalCreditCard.open();
    },
    async updateCreditCard(payJpCardId) {
      try {
        this.loadingStart();
        const {data} = await this.$apollo.mutate({
          mutation: UpdateOrderCreditCard,
          variables: {
            input: {
              orderId: this.order.id,
              payJpCardId: payJpCardId,
            }
          },
          client: 'apiClient'
        });
        const errors = data?.updateOrderCreditCard?.errors;
        if (!errors) {
          this.$emit("updateCreditCard", data.updateOrderCreditCard);
          this.$refs.ModalCreditCard.close();
        } else {
          const messages = errors.map(error => error.message);
          this.setToast({toast: {type: 'error', messages: messages}});
        }
      } catch (error) {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const graphQLErrors = error.graphQLErrors.map(e => e.message);
          this.setToast({toast: {type: 'error', messages: graphQLErrors}});
        }
      } finally {
        this.loadingStop();
      }
    }
  }
};

</script>
<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";

.detail-container {
  .section-accordion {
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: space-between;
    align-items: center;

    > p {
      font-weight: $font-weight-bold;
      cursor: pointer;
    }

    > .svg {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;

      > svg {
        height: auto;

        > path {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  > .detail-wrapper {
    padding: 0 16px;

    > .payment,
    .payment-methods,
    .reserve-shipping,
    .deliver,
    .shipping,
    .customer {
      padding: 16px 0;
      border-bottom: 1px solid $gray-300;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      > h3 {
        padding-bottom: 4px;
      }
    }

    > .payment {
      > .payment-detail {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;

        > .total {
          font-weight: $font-weight-bold;
        }
      }
    }

    > .reserve-shipping {
      > .info-accordion {
        background-color: $gray-100;
        padding: 8px;

        > .title {
          display: flex;

          > .svg {
            width: 24px;
            text-align: center;
          }
        }

        > .details {
          margin-top: 8px;
        }
      }
    }

    > .shipping {
      > .shipping-detail {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
      }
    }

    > .remarks {
      padding: 16px 0;

      > h3 {
        padding-bottom: 4px;
      }

      > p {
        white-space: pre;
      }
    }
  }

  > .inquiry {
    border-top: 1px solid $gray-300;
    padding: 16px;

    > .button-base {
      width: 100%;

      + .button-base {
        @include margin-top(xs);
      }
    }
  }

}

</style>