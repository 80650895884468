<template>
  <button class="button-favorite" @click="click()" :class="classes">
    <FavoriteSvg />
  </button>
</template>

<script>
  import { CreateFavoriteItem } from '../../../graphqls/FavoriteItems'
  import { DeleteFavoriteItem } from '../../../graphqls/FavoriteItems'
  import GlobalMixin from '../../../mixin/GlobalMixin'
  import FavoriteSvg from "../../svg/FavoriteSvg.vue";

  export default {
    name: 'ButtonFavorite',
    components: {FavoriteSvg},
    mixins: [GlobalMixin],
    props: {
      position: {
        type: String,
        required: true,
        default: ''
      },
      item: {
        type: Object,
        required: true,
        default: {}
      },
      itemGroup: {
        type: Object,
        required: true,
        default: {}
      },
    },
    computed: {
      classes: function () {
        return [
          {'-favorite': this.isFavorite}
        ]
      },
      isFavorite: function () {
        return this.$store.getters.favoriteItems.some(favoriteItem => favoriteItem.itemId === this.item.id)
      },
      getFavoriteItem: function () {
        return this.$store.getters.favoriteItems.find(favoriteItem => favoriteItem.itemId === this.item.id)
      }
    },
    methods: {
      click: function () {
        if(this.isFavorite) {
          this.removeFavorite()
        } else {
          this.addFavorite()
        }
      },
      async addFavorite() {
        const { data } = await this.$apollo
        .mutate({
          mutation: CreateFavoriteItem,
          variables: {
            input: {
              itemId: this.item.id
            }
          }
        })
        this.$store.dispatch('addFavoriteItem', data.createFavoriteItem.favoriteItem)
        this.setToast({ toast: { type: 'success', messages: ['お気に入りに追加しました'] }})
        this.$_gaSendEvent('お気に入り追加', `商品詳細SKU(${this.position})`, `${this.itemGroup.adminShop.name} ${this.item.displayName}`)
        this.$gtag.event('add_to_wishlist', {
          currency: 'JPY',
          items: [{
            item_id: this.itemGroup.id,
            item_name: `${this.itemGroup.adminShop.name} ${this.itemGroup.name}`,
            affiliation: this.itemGroup.adminShop.code,
            item_brand: this.itemGroup.adminShop.name,
          }]
        })
      },
      async removeFavorite() {
        const { data } = await this.$apollo
        .mutate({
          mutation: DeleteFavoriteItem,
          variables: {
            input: {
              id: this.getFavoriteItem.id
            }
          }
        })
        this.$store.dispatch('removeFavoriteItem', this.getFavoriteItem.id)
        this.setToast({ toast: { type: 'success', messages: ['お気に入りを削除しました'] }})
        this.$_gaSendEvent('お気に入り削除', `商品詳細SKU(${this.position})`, `${this.itemGroup.adminShop.name} ${this.item.displayName}`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/stylesheets/v1/styles";

.button-favorite {
  height: 18px;
  width: 18px;
  background: $white;
  border-radius: 50%;
  border: none;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25));
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  > svg {
      fill: none;
      stroke: $olive-300;
      position: absolute;
      top: 2px;
  }

  &.-favorite {
    > svg {
      fill: $olive-300;
      stroke: none;
    }
  }
}
</style>
