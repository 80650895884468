<template lang="pug">
.terms-wrap
  .use
    h1
      span.border たべるとくらすと利用規約
    p この利用規約（以下，「本規約」といいます。）は，株式会社ess（以下，「当社」といいます。）が提供する たべるとくらすと に関するすべてのサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用する利用者（以下「お客様」といいます。）と当社との間で定めるものです。お客様は、本サービスを利用するにあたり、本規約を順守しなければなりません。
    h2 第1条（定義）
    p 本規約では、以下の用語を使用します。
    ol
      li 「本サービス」とは株式会社essが提供する たべるとくらすと に関する全てのサービスを言います。
      li 「当社」とは本サービスの提供者である株式会社essをいいます。
      li 「お客様」とは、本サービスを利用する全ての者をいいます。
      li 「販売者」とは、本サービスを利用して商品等を出品する者をいいます。
      li 「商品」とは、販売者が本サービスを利用して販売する商品をいいます。
      li 「ポイント」とは、当社が指定するポイントプログラムにより付与されるポイントをいいます。
      li 「コンテンツ」とは、文章、音声、音楽、画像、動画、ソフトウェア、プログラム、コードその他の情報のことをいいます。
    h2 第2条（本規約の適用及び変更）
    ol
      li お客様は、本規約に従って本サービスを利用するものとします。お客様が利用申込みを行い、又は本サービスを利用することによって、本規約の内容を全て承諾したものとみなします。
      li 当社は、いつでも任意の理由により、お客様に事前に通知することなく、本規約の変更、追加、又は削除（以下、「変更等」といいます。）を行うことができるものとします。変更等が行われた場合の本規約は、本サービスサイト上に表示された時点より効力を生じるものとします。当社は、本規約の変更等が行われた後において、お客様が本サービスを利用することによって、変更等後の本規約の内容を承諾したものとみなします。
    h2 第3条（個人情報の取扱い）
    ol
      li 当社は、個人情報等を、原則としてお客様本人以外の者に開示、提供せず、本サービスを提供するために必要な範囲を超えて利用しません。当社が取得したお客様の個人情報は、別途定める当社の<a href="#" class="privacy">プライバシーポリシー</a>に従って取り扱われます。また当社は、本サービス向上およびお客様に対する商品に関する情報提供のため、お客様の個人情報を利用することができるものとします。
    h2 第4条（サービス内容の変更・終了等）
    ol
      li 当社は、お客様等に事前に通知することなく、当社の任意の判断で、サービスの全部又は一部を終了・一時停止・変更・追加できるものとします。
      li 当社は、できるだけ前項に関わる予告を行う努力は致しますが、前項のサービスの終了・一時停止・変更・追加に起因する一切の損害について、お客様等及び第三者に対して一切責任を負わないものとします。
    h2 第5条（通知または連絡）
    ol
      li 当社又は販売者は、お客様に通知及び連絡の必要があると当社が判断した場合、お客様が登録した情報に記載されている電子メールアドレス、 電話番号、又は住所に対し、電子メール、電話、ショートメール、又は郵便を用いて通知及び連絡を行います。上記,連絡先に対する連絡がつながらなかった場合、当社は、当社からの通知又は連絡が受け取れなかったことによりお客様に生じた損害について、一切責任を負わないものとします。
      li お客様が当社または販売者に通知、連絡、問合せをする必要が生じた場合、当社が提供する本サービス上のお問い合わせフォームに対し行うこととします。
      li 前項に基づきお客様から問合せがあった場合、当社又は販売者は、その時点で定めている方法により、お客様の本人確認を行うことができるものとします。また、問合せに対する回答方法（電子メール、ショートメール、書面による郵送、電話など）については、その都度当社が最適と考える回答方法を利用して回答することができるものとします。
    h2 第6条（利用登録）
    ol
      li お客様は、本サービスの利用に際してお客様ご自身に関する情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報となるよう修正しなければなりません。
      li 利用登録手続は、お客様の申込に対する当社の承諾をもって完了するものとします。
      li 利用登録手続の完了時に、本利用規約の諸規定に従った本サービスの利用契約がお客様と当社の間に成立し、お客様は本サービスを当社の定める方法で利用することができるようになります。
      li 当社は、利用希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。なお、当社は、利用希望者が未成年である場合（18歳以上で法定代理人の同意を得ている場合を除く。）には、いかなる場合でも登録を認めないものとします。
        ol
          li 本利用規約に違反するおそれがあると当社が判断した場合
          li 当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
          li 過去に本サービスの利用の登録を取り消された者である場合
          li 未成年者（18歳以上で法定代理人の同意を得ている場合を除く。）、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合。なお、当社はかかる同意等を示す書面の提出を求めることがありますが、かかる書面が提出された場合でも、登録を拒否することがあります。
          li その他、当社が登録を適当でないと判断した場合
      li 本サービスに登録したお客様は、いつでもアカウントを削除して退会することができます。但し、退会の手続を行った時点で、取引の決済など、取引の手続が未完のものがある場合は退会することができないことから、お客様は、 一連の未完の取引を本規約に従って遅滞なく円滑に進め完了させた後、退会手続きを行うものとします。
      li お客様の本サービスにおけるすべての利用権（ポイントを含む）は、理由を問わず、アカウントが削除された時点で消滅します。お客様が誤ってアカウントを削除した場合であっても、登録情報、取得ポイント、取得予定ポイントの復旧はできません。この場合、お客様に生じる損害については、当社は一切の責任を負わないものとします。
      li 本サービスのアカウントは、お客様に一身専属的に帰属します。お客様の本サービスにおけるすべての利用権は、第三者に譲渡、貸与または相続させることはできません。
    h2 第7条（メールアドレスおよびパスワードの管理）
    ol
      li お客様は、当社の定めるところに従い、メールアドレスおよびパスワード等を第三者に利用させ、または譲渡もしくは担保設定その他の処分をすることはできないものとします。
      li お客様は、本サービス上で設定するメールアドレス、パスワード等の管理を行う責任を負うものとします。
      li 当社は、登録されたパスワードを利用して行なわれた一切の行為を、お客様ご本人の行為とみなすことができます。
      li メールアドレスおよびパスワード等の管理不十分、使用上の過誤、第三者の使用等による損害の責任はお客様が負うものとし、当社は一切の責任を負わないものとします。
      li メールアドレスおよびパスワードの情報が第三者に漏洩した場合、速やかに当社までご連絡下さい。なお、当社は、当該メールアドレスとパスワードによるサービス利用の停止又は終了を行うことはできますが、その情報漏洩によって生じたあらゆる損害について一切の責任を負わないものとします。但し、当社の故意によりメールアドレス及びパスワードが第三者に知られた場合はこの限りではありません。
    h2 第8条（利用資格の停止・取り消し）
    ol
      li 当社は次の各号に該当すると判断した場合、事前に通知することなく、本サービスの利用停止、該当メールアドレスおよびパスワードの変更、または利用資格の取消しを行うことができるものとします。これによりお客様の本サービスにおけるポイントを含むすべての利用権は、理由を問わず、利用資格が取り消された時点で消滅します。これによりお客様に何らかの損害が生じたとしても、当社は一切の責任を負わないものとします。
        li 本利用規約に違反するおそれがあると当社が判断した場合
        li 法令や本規約等に違反する行為があった場合
        li メールアドレス、パスワードまたは本サービスを不正に使用しまたは使用させた場合
        li 当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
        li 当社、他のお客様、販売者、その他の第三者に損害を生じさせるおそれのある目的又は方法で本サービスを利用した、又は利用しようとした場合
        li 手段の如何を問わず、本サービスの運営を妨害した場合
        li 過去に本サービスの利用の登録を取り消された者である場合
        li 1ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合
        li 18歳以上の未成年者のうち、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合。なお、当社はかかる同意等を示す書面の提出を求めることがありますが、かかる書面が提出された場合でも、登録を削除することがあります
        li その他、当社が登録を適当でないと判断した場合
      li お客様が死亡した場合、その他本人の利用資格の利用が不可能となる事由があったときは、当社は、当該お客様がその時点で退会したものとみなし、メールアドレスおよびパスワードを利用できなくするものとします。但し、退会時において未済の取引がある場合は、お客様は速やかにこれを完了させるものとし、その完了後に退会が認められるものとします。
      li 当社は、本条の措置により生じる一切の損害について、一切の責任を負わないものとします。当社は、本条の措置の時点で当該お客様に支払われることとなっていた金銭について、 当社の判断により、一定期間その支払を留保したり、支払をせずに当社が指定する他の方法により精算することができるものとします。
    h2 第9条（商品の購入の申し込み等）
    ul
      li お客様は、当社の定める手続きに従い、本サービスを通じて、販売者へ商品の購入を申し込むことができます。なお、当社は、お客様および販売者双方の便宜向上のために、申し込み手続きの仕様等を随時変更することがあります。
      li お客様は、商品等の購入を希望する場合、お客様が入力・登録した配達先・注文内容等を確認の上で当社が指定する方法に従って、商品等の購入を申込むものとします。
      li お客様が申し込み手続きを完了した時点では、お客様と販売者との間に、当該申し込みにかかる商品の売買契約は成立しておりません。
      li 販売者より申し込みを承諾する旨の連絡が送信されたとき又は商品の発送が行なわれたときのいずれか早い方で売買契約が成立します。
      li 申し込み手続き完了後に、販売者が送料・配送方法等の取引条件を変更する場合あります。取引条件の重要な要素が変更された場合、ショップが変更内容を通知します。お客様は、ショップからの通知内容を確認しなければなりません。
      li 本サービス利用に関して不正行為又は不適当な行為があった場合、当社は売買契約について取消、解除その他の適切な措置を取ることができるものとします。
      li 本サービスによる商品等の配送は、日本国内に限ります。
    h2 第10条（支払方法）
    ul
      li 商品等の支払い金額は、消費税を含む商品等の購入代金及びこれに係わる取り扱い手数料の合計となります。
      li 本サービスによって購入された商品等の支払いに関しては、お客様本人名義のクレジットカードによる支払、又は当社が別途認める支払方法に限るものとします。
      li クレジットカードで支払われる場合は、お客様がクレジットカード会社との間で別途契約する条件に従うものとします。なお、クレジットカードの利用に関連して、お客様とクレジットカード会社等の間で何らかの紛争が発生した場合は、お客様とクレジットカード会社との間で責任をもって解決するものとします。
    h2 第11条（禁止事項）
    p お客様は、以下の行為を一切行ってはならないものとします。万一、これに違反して当社又は第三者に損害が生じた場合、当該お客様がその損害を全て賠償する責任を負うものとします。
    ol
      li 他のお客様、他のお客様以外の第三者、若しくは当社に迷惑、不利益若しくは損害を与える行為、又はそれらのおそれのある行為
      li 他のお客様、他のお客様以外の第三者、若しくは当社の著作権等の知的財産権、肖像権、人格権、プライバシー権、パブリシティ権その他の権利を侵害する行為又はそれらのおそれのある行為
      li 本サービスを商業目的で利用する行為（但し、当社が予め認めたものは除きます。）
      li 公序良俗に反する行為その他法令に違反する行為又はそれらのおそれのある行為
      li 虚偽又は誤解を招くような内容を含む情報を登録する行為
      li 本サービスを通じて入手したコンテンツをお客様が私的使用の範囲外で使用する行為
      li 他のお客様、又は他のお客様以外の第三者を介して、本サービスを通じて入手したコンテンツを複製、販売、出版、頒布、公開及びこれらに類似する行為
      li 他のお客様の個人情報を収集、蓄積又は保存をする行為
      li コンピューターのソフトウェア、ハードウェア、通信機器の機能を妨害、破壊、制限するように設計されたコンピューターウィルス、コンピューターコード、ファイル、プログラム等のコンテンツを本サービスにアップロードしたり、メール等の手段で送信したりする行為
      li 同一お客様が複数の利用登録をおこなう行為（パソコン、携帯電話又はスマートフォン何れから複数登録する行為も含むものとします。但し、当社が予め認めたものは除きます。
      li 過度に商品を返品する行為
      li 購入する意図なく商品の購入を申し込むこと
      li 正当な理由なく商品を受け取らないこと
      li 正当な理由なく返品等をすること
      li ポイントの不正取得、ポイントを不正な目的に利用すること
      li その他当社の信用を毀損・失墜させる等の当社が不適当であると合理的に判断する行為
    h2 第12条（知的財産権）
    ol
      li 本サービスに関する著作権等の知的財産権は、別段の定めのない限り全て当社に帰属し、当社の定めた方法以外で、当社の許可がない限り本サービス以外で利用することはできないものとします。
      li 当社は、当社の裁量により、本サービスに関連してお客様が発信したコンテンツの全部または一部を自由に利用できるものとします。当該コンテンツを利用するにあたり、当社は投稿者に対して一切の支払を必要としないものとします。
      li お客様は第三者が著作権、商標権、意匠権等の知的財産権を有する著作物、標章、サービスマーク、デザイン、表示等を本サービス上に掲載する場合は、自らの責任において当該知的財産権の権利者より許諾を得る必要があり、当社はこれについて一切責任を負わないものとします。
      li お客様が他人の名誉を毀損した場合、著作権法に違反する行為を行った場合そのほか他人の権利を侵害した場合には、当該お客様は自身の責任と費用においてこれを解決しなければならず、当社は一切の責任を負わないものとします。
    h2 第13条（免責事項）
    ol
      li 当社は、本サービスの運用にその時点での技術水準を前提に最善を尽くしますが、障害が生じないことを保証するものではありません。通信回線やコンピュータなどの障害によるシステムの中断・遅滞・中止・データの消失、ポイント利用に関する障害、データへの不正アクセスにより生じた損害、その他本プログラムに関してお客様に生じた損害について、当社は一切責任を負わないものとします
      li 本サービスから他のウェブサイト若しくはリソースへのリンク、又は第三者のウェブサイト若しくはリソースから本サービスへのリンクを提供している場合、当社は、当該リンク先の内容、利用及びその結果（適法性、有効性、正確性、確実性、安全性、最新性及び完全性を含みますが、これらに限られません。）については、いかなる責任も負わないものとします。なお、当社は、リンク先のウェブサイト又はリソースの内容が、違法又は本サービスの管理・運営上不適切であると合理的に判断した場合には、お客様に何らの通知を要することなく、当該リンクを削除することができるものとします。
      li 本サービス中に広告又は宣伝を行っている広告主との取引がある場合、お客様は、自らの判断と責任により、当該広告主との間で取引を行うものであり、これに関して当社は一切責任を負わないものとします。商品等の代金の支払、契約条件の決定、保証、担保責任、ライセンスの有無等の取引に関する内容・条件は、一切、当社が保証するものではなく、当社は、本サービス中に掲載されている広告又は宣伝を経由して行われる取引に起因して、お客様に何らかの損害については一切責任を負わないものとします。
      li 当社は、お客様の登録内容に従い事務を処理することにより、当社の債務を履行し免責されるものとします。
      li お客様が、本サービスを利用することにより、他のお客様又は第三者に対して何らかの損害等を与えた場合には、当該お客様はその責任と費用においてこれを解決し、当社には一切の損害、損失、不利益等を与えないものとします。
    h2 第14条（準拠法・裁判管轄）
    p 本規約の解釈にあたっては，日本法を準拠法とします。<br>本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
    h2 本規約の変更
    p 平成29年5月2日 制定
    p 令和3年3月11日 改定
    p.pullright 以上
  .point
    h1
      span.border ポイントプログラム利用規約
    p この規約は、株式会社ess（以下「当社」といいます）が運営・提供するたべるとくらすと に関するすべてのサービス利用規約に基づき利用登録をした利用者（以下「お客様」といいます）に対して、ポイントプログラム（以下「本プログラム」といいます）を提供するにあたり、その諸条件を定めるものです。本プログラムに関し本規約に規定のない事項については、たべるとくらすと利用規約が適用されます。
    h2 第1条（定義）
    p 本規約では、以下の用語を使用します。
    ol
      li 「本プログラム」とは株式会社株式会社essが提供・運営する たべるとくらすと に関するすべてのサービスにおいて、当社が指定するポイントプログラムにより付与されるポイントを言います。
      li 「当社」とは本プログラムの提供・運営者である株式会社essをいいます。
      li 「お客様」とは、 たべるとくらすと を利用する全ての者をいいます。
      li 「販売者」とは、 たべるとくらすと を利用して商品等を出品する者をいいます。
      li 「商品」とは、販売者が たべるとくらすと を利用して販売する商品をいいます。
      li 「商品価格」とは、注文額をいい、消費税および地方消費税、送料や手数料等は含まないものとします。
    h2 第2条（ポイントの付与）
    ol
      li 当社は、当社が提供・運営する たべるとくらすと にかかる全てのサービスで指定する方法で物品等の購入したとき、その他当社が相当と認めた場合に、ポイントを付与します。
      li ポイント付与の対象となるサービスおよび取引（以下「対象取引」といいます）、ポイントの付与率、その他ポイント付与の条件は、当社が決定するものとします。
      li ポイント付与の対象となるか否か、ポイントの付与率、および有効期限は、販売者、取引の種類、または利用サービスの種類によって異なることがあります。
      li ポイントは、対象取引が行われてから、当社が定める一定の期間を経た後に付与されます。この期間内に、当社が対象取引につき取消、返品などがあったことを確認した場合、対象取引にポイントは付与されません。
      li ある取引についてポイントを付与するか否か、付与するポイント数、その他ポイントの付与に関する最終的な判断は、当社が行うものとし、お客様はこれに従うものとします。
    h2 第3条（ポイント付与率の計算）
    ol
      li たべるとくらすとのIDログインして商品等を購入したお客様に対して、一定のポイント付与率（以下「ポイント付与率」といいます）に基づき計算されたポイントを付与します。
      li 当社の定める標準のポイント付与率（以下「標準ポイント付与率」といいます）は、商品価格の1％ですが、販売者においてそれより上のポイント付与率を設定することができます。
      li ポイント付与率の計算は、1つの商品ごとにおこなわれます。
      li 付与されるポイント数の計算は、商品価格×ポイント付与率で算出します。算出されたポイント数は小数点以下を切り捨てとします。例）商品 A（商品価格 2,160 円）を標準ポイント付与率1％にて1個購入した場合の計算式は2,160×0.01=21（小数点以下切捨）となり、21ポイントが付与されます。
      li 各商品等に対して付与されるポイント数の計算は、ポイント付与率に基づき、当社がおこないます。
    h2 第4条（仮ポイント）
    ol
      li お客様がたべるとくらすとIDでログインして注文をした直後に付与されるポイントを仮ポイントとします。
      li 仮ポイントは、注文からポイントが確定的に付与されるまでの間、将来付与されるポイントをご確認いただけるように暫定的に発行されるポイントです。ポイントが仮ポイントの状態のときには、お客様はポイントを利用できません。
      li 仮ポイントの発行からポイントが確定的に付与されるまでの期間を「仮ポイント期間」といいます。仮ポイントは、設定した仮ポイント期間を経過後に自動的に確定します（以下確定したポイントを「確定ポイント」といいます）。
    h2 第5条（ポイントの管理）
    ol
      li 当社は、当社所定の方法により、お客様が獲得したポイント数、お客様が使用したポイント数およびポイント数の残高を、お客様に告知します。
      li お客様は、ポイント数に疑義のある場合には、ただちに当社に連絡し、その内容を説明するものとします。
      li ポイント数に関する最終的な決定は当社が行うものとし、お客様はこれに従うものとします。
    h2 第6条（ポイントの合算および複数登録の禁止）
    ol
      li お客様は、保有するポイントを他のお客様に譲渡または質入れしたり、お客様間でポイントを共有したりすることはできません。
      li 一人のお客様が複数のアカウント登録をしている場合、お客様はそれぞれのアカウント登録において保有するポイントを合算することはできません。
    h2 第7条（ポイントの取消・消滅）
    ol
      li お客様は、ポイントを獲得した日から1年間の期間中にのみポイントを利用することができます。有効期限を過ぎた未使用のポイントは消滅し、その後利用することはできません。
      li 当社がポイントを付与した後に、対象取引について返品、キャンセルその他当社がポイントの付与を取り消すことが適当と判断する事由があった場合、当社は、対象取引により付与されたポイントを取り消すことができます。
      li 当社は、お客様が次の各号のいずれかに該当すると判断した場合、当社はお客様に事前に通知することなく、お客様が保有するポイントの一部または全部を取り消すことができます。
        ol
          li 違法または不正行為があった場合
          li 本規約、お客様規約、その他当社が定める規約・ルール等に違反があった場合
          li その他当社がお客様に付与されたポイントを取り消すことが適当と判断した場合
      li お客様は、当社が定める期間を超えて当該対象取引を行わなかった場合、ポイントは自動的に消滅します。
      li 当社は、取消または消滅したポイントについて何らの補償も行わず、一切の責任を負いません。
    h2 第8条（決済におけるポイントの利用）
    ol
      li お客様は自身の持つ利用可能なポイントを1回につき1ポイントから利用でき、お客様がポイントを利用した場合、販売者は、お客様に対して1ポイント＝1円の換価率に従い、利用されたポイント（以下「利用ポイント」といいます）相当額を商品代金から値引きして販売します。
      li ポイント利用の対象は、商品代金、送料、手数料、税金、その他のたべるとくらすとIDでログインして取引したお客様が支払う一切の金額とします。
      li お客様が注文で発生した販売者への支払いの全額につきポイントを利用した場合で、その後、注文内容の変更によりお客様の支払うべき代金が増額した場合、当該増額分についてはポイントを利用することはできず、それ以外の方法により支払うこととします。
      li 当社は、ポイント利用の対象となるサービス・商品等を制限したり、ポイント利用に条件を付したりすることがあります。
      li お客様が決済を取り消した場合、原則として商品出荷前であれば当該決済に利用されたポイントが返還されます。現金による返還は行われません。（出荷後の取り消しの場合、ポイントは返還されません。）
      li お客様の保有するポイントは当社の規定に従い、消化されます。
    h2 第9条（事故等）
    ol
      li ポイントによる決済対象となった商品につき、その配送中または提供後に遅延、紛失、盗難、損害、破損等の事故が生じた場合は、当該事故が当社の責任による場合を除き、当社は一切責任を負わず、ポイントの払い戻しも行いません。
    h2 第10条（ポイント利用後のポイントの取り消し）
    ol
      li お客様がポイントを決済に利用した後に、ポイントが取り消された場合は、当該決済の対象となる取引（以下「ポイント利用取引」という）が取消または保留されることがあります。お客様は、ポイント利用取引が実行済みである場合または実行しようとする場合には、ポイント取消による不足額を、ただちに現金または当社の指定する支払方法にて当社に支払うものとします。
    h2 第11条（換金の不可）
    ol
      li お客様は、いかなる場合でもポイントを換金することはできません。
    h2 第12条（第三者による使用）
    ol
      li ポイントの使用は、お客様本人が行うものとし、当該お客様以外の第三者が行うことはできません。
      li 当社は、ポイント使用時に入力されたメールアドレスおよびパスワードおよびユーザーIDが登録されたものと一致することを当社が所定の方法により確認した場合には、お客様による使用とみなします。それが第三者による不正使用であった場合でも、当社は使用されたポイントを返還しませんし、お客様に生じた損害について一切責任を負いません。
    h2 第13条（税金及び費用）
    ol
      li ポイントの取得、ポイントの利用にともない、税金や付帯費用が発生する場合には、お客様がこれらを負担するものとします。
    h2 第14条（お客様資格の喪失・停止）
    ol
      li お客様がお客様の地位を喪失した場合には、保有するポイント、特典との交換権、その他本プログラムの利用に関する一切の権利を失うものとし、また地位の喪失にともなって当社に対して何らの請求権も取得しないものとします。
    h2 第15条（免責）
    ol
      li 当社は、本プログラムの運用にその時点での技術水準を前提に最善を尽くしますが、障害が生じないことを保証するものではありません。通信回線やコンピュータなどの障害によるシステムの中断・遅滞・中止・データの消失、ポイント利用に関する障害、データへの不正アクセスにより生じた損害、その他本プログラムに関してお客様に生じた損害について、当社は一切責任を負わないものとします
    h2 第16条（本プログラムの変更）
    ol
      li 当社は、お客様に事前に通知することなく、本規約、本プログラムの内容または本プログラム提供の条件の変更（ポイントの廃止、ポイント付与の停止、対象サイトまたは取引の変更、ポイント付与率または利用率の変更を含みますが、これらに限られません）を行うことがあり、本プログラムを終了または停止することがあります。お客様はこれをあらかじめ承諾するものとします。
      li 当社は、前項の変更によりお客様に不利益または損害が生じた場合でも、これらについて一切責任を負わないものとします。
    h2 第17条（規約の変更）
    ol
      li 当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、本プログラムおよび個別利用規約を変更できるものとします。変更後の本プログラムおよび個別利用規約は、本サービス内の適宜の場所に掲示された時点からその効力を生じるものとし、お客様は本プログラムおよび個別利用規約の変更後も本サービスを使い続けることにより、変更後の本プログラムおよび適用のある個別利用規約に対する有効かつ取消不能な同意をしたものとみなされます。かかる変更の内容をお客様に個別に通知することはいたしかねますので、本サービスをご利用の際には、随時、最新の本プログラムおよび適用のある個別利用規約をご参照ください。
    h2 第18条（言語）
    ol
      li 本規約は、日本語を正文とします。本規約につき、参考のために英語等による翻訳文が作成された場合でも、日本語の正文のみが契約として効力を有するものとし、英訳等はいかなる効力も有しないものとします。
    h2 第19条（準拠法、裁判管轄）
    ol
      li 本規約の準拠法は日本法とします。
      li 本規約の解釈を巡って疑義が生じた場合、当社は、合理的な範囲でその解釈を決定することができるものとします。
      li 本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
    h2 本規約の変更
    p 平成29年5月2日 制定
    p 令和3年3月11日 改定
    p.pullright 以上
</template>

<script>
export default {
  name: "Terms",
};
</script>

<style lang="scss" scoped>
@import "../../../../../assets/stylesheets/v1/styles";
.terms-wrap {
  .use {
    color: $black-400;
    margin-top: 3rem;
    margin-bottom: 3rem;

    > h1 {
      margin-bottom: 2rem;
      text-align: center;
      font-family: $font-family-serif;
      @include font-size(md);
      line-height: $line-height-tight;
      font-weight: $font-weight-regular;

      > .border {
        position: relative;
        display: inline-block;

        &::before,
        &::after {
          position: absolute;
          bottom: 2px;
          left: 0;
          content: "";
          height: 4px;
          z-index: -1;
        }

        &::after {
          width: 100%;
          background: repeating-linear-gradient(
            -45deg,
            $white,
            $white 1px,
            $olive-300 1px,
            $olive-300 2px
          );
        }
      }
    }

    > p {
      font-family: $font-family-sans;
      @include font-size(xs);
      line-height: $line-height-normal;
      font-weight: $font-weight-regular;
      margin-bottom: 1rem;
    }

    > h2 {
      font-family: $font-family-serif;
      @include font-size(md);
      line-height: $line-height-tight;
      margin: 3rem auto 1.5rem;
      font-weight: $font-weight-regular;
    }

    > h3 {
      margin-bottom: 10px;
      font-family: $font-family-serif;
      @include font-size(sm);
      line-height: $line-height-none;
      font-weight: $font-weight-regular;
    }

    > ol {
      padding-left: 1.2rem;

      > li {
        list-style: auto;
        font-family: $font-family-sans;
        @include font-size(xs);
        line-height: $line-height-tight;
        @include margin-bottom(sm);
        font-weight: $font-weight-regular;
        margin-bottom: 0.5rem;

        &::marker {
          unicode-bidi: isolate;
          font-variant-numeric: tabular-nums;
          text-transform: none;
          text-indent: 0px !important;
          text-align: start !important;
          text-align-last: start !important;
        }

        > .privacy {
          color: $black-400;

          &:hover {
            color: $olive-300;
          }
        }

        > ol {
          margin-top: 0.5rem;
          padding-left: 1.2rem;

          > li {
            list-style: auto;
            font-family: $font-family-sans;
            @include font-size(xs);
            line-height: $line-height-tight;
            @include margin-bottom(sm);
            font-weight: $font-weight-regular;
            margin-bottom: 0.5rem;

            &::marker {
              unicode-bidi: isolate;
              font-variant-numeric: tabular-nums;
              text-transform: none;
              text-indent: 0px !important;
              text-align: start !important;
              text-align-last: start !important;
            }

            > ol {
              margin-top: 0.5rem;
              padding-left: 1.2rem;

              > li {
                list-style: auto;
                font-family: $font-family-sans;
                @include font-size(xs);
                line-height: $line-height-tight;
                @include margin-bottom(sm);
                font-weight: $font-weight-regular;
                margin-bottom: 0.5rem;

                &::marker {
                  unicode-bidi: isolate;
                  font-variant-numeric: tabular-nums;
                  text-transform: none;
                  text-indent: 0px !important;
                  text-align: start !important;
                  text-align-last: start !important;
                }
              }
            }
          }
        }
      }
    }

    > ul {
      > li {
        font-family: $font-family-sans;
        @include font-size(xs);
        line-height: $line-height-tight;
        font-weight: $font-weight-regular;
      }
    }

    > .pullright {
      text-align: right;
    }
  }
  .point {
    color: $black-400;
    margin-top: 3rem;
    margin-bottom: 3rem;

    > h1 {
      margin-bottom: 2rem;
      text-align: center;
      font-family: $font-family-serif;
      @include font-size(md);
      line-height: $line-height-tight;
      font-weight: $font-weight-regular;

      > .border {
        position: relative;
        display: inline-block;

        &::before,
        &::after {
          position: absolute;
          bottom: 2px;
          left: 0;
          content: "";
          height: 4px;
          z-index: -1;
        }

        &::after {
          width: 100%;
          background: repeating-linear-gradient(
            -45deg,
            $white,
            $white 1px,
            $olive-300 1px,
            $olive-300 2px
          );
        }
      }
    }

    > p {
      font-family: $font-family-sans;
      @include font-size(xs);
      line-height: $line-height-normal;
      font-weight: $font-weight-regular;
      margin-bottom: 1rem;
    }

    > h2 {
      font-family: $font-family-serif;
      @include font-size(md);
      line-height: $line-height-tight;
      margin: 3rem auto 1.5rem;
      font-weight: $font-weight-regular;
    }

    > h3 {
      margin-bottom: 10px;
      font-family: $font-family-serif;
      @include font-size(sm);
      line-height: $line-height-none;
      font-weight: $font-weight-regular;
    }

    > ol {
      padding-left: 1.2rem;

      > li {
        list-style: auto;
        font-family: $font-family-sans;
        @include font-size(xs);
        line-height: $line-height-tight;
        @include margin-bottom(sm);
        font-weight: $font-weight-regular;
        margin-bottom: 0.5rem;

        &::marker {
          unicode-bidi: isolate;
          font-variant-numeric: tabular-nums;
          text-transform: none;
          text-indent: 0px !important;
          text-align: start !important;
          text-align-last: start !important;
        }

        > .privacy {
          color: $black-400;

          &:hover {
            color: $olive-300;
          }
        }

        > ol {
          margin-top: 0.5rem;
          padding-left: 1.2rem;

          > li {
            list-style: auto;
            font-family: $font-family-sans;
            @include font-size(xs);
            line-height: $line-height-tight;
            @include margin-bottom(sm);
            font-weight: $font-weight-regular;
            margin-bottom: 0.5rem;

            &::marker {
              unicode-bidi: isolate;
              font-variant-numeric: tabular-nums;
              text-transform: none;
              text-indent: 0px !important;
              text-align: start !important;
              text-align-last: start !important;
            }

            > ol {
              margin-top: 0.5rem;
              padding-left: 1.2rem;

              > li {
                list-style: auto;
                font-family: $font-family-sans;
                @include font-size(xs);
                line-height: $line-height-tight;
                @include margin-bottom(sm);
                font-weight: $font-weight-regular;
                margin-bottom: 0.5rem;

                &::marker {
                  unicode-bidi: isolate;
                  font-variant-numeric: tabular-nums;
                  text-transform: none;
                  text-indent: 0px !important;
                  text-align: start !important;
                  text-align-last: start !important;
                }
              }
            }
          }
        }
      }
    }

    > ul {
      > li {
        font-family: $font-family-sans;
        @include font-size(xs);
        line-height: $line-height-tight;
        font-weight: $font-weight-regular;
      }
    }

    > .pullright {
      text-align: right;
    }
  }
}
</style>