import gql from "graphql-tag";

export const GetOrder = gql`
query($orderId: ID!) {
  order(orderId: $orderId) {
    id
    code
    orderedAt
  }
}
`

export const GetOrderDetail = gql`
query($orderId: ID!) {
  order(orderId: $orderId) {
    id
    code
    orderedAt
    contact {
      contactComments {
        id
        userId
        adminUserId
        comment
        image
        imageUrl
        elapsedTime
        user {
          nickname
          name
        }
        adminUser {
          adminShop {
            name
            logoImageSet {
              smSqImage
            }
          }
        }
      }
      userNotice {
        id
      }
    }
  }
}
`

export const GetHasOrders = gql`
query ($perPage: Int, $search: String) {
  orders (perPage: $perPage, search: $search) {
    items {
      id
    }
  }
}
`

export const GetPurchaseHistories = gql`
query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
  orders (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
    items {
      id
      code
      formatOrderedOn
      subscriptionOrderId
      adminShop {
        name
        code
        logoImageSet {
          xsSqImage
        }
        publishedStatus
      }
      currentOrderSummary {
        id
        orderStatus
        orderStatusLabel
      }
      currentOrderPayment {
        id
        itemTotalPriceInTaxWithCurrency
        orderTotalPriceWithCurrency
        shippingPriceInTaxWithCurrency
        codFeeInTaxWithCurrency
        usePoint
        usePointWithCurrency
        couponDiscountAmountWithCurrency
        couponDiscountAmount
        paymentTypeLabel
        paymentType
        isAddTax
        payJpCardId
        isErrorCreditCard
      }
      currentOrderDelivery {
        id
        name
        zipCode
        address
        tel
        formatScheduledDeliveryOn
        displayDeliveredOn
        deliveryOn
        displayDeliveryOn
        deliveryTime
        displayDeliveryTime
        deliveryCompanyLabel
        trackingNumber
        memo
        itemDeliveryMethod {
          name  
        }
      }
      currentOrderContactInformation {
        id
        name
        zipCode
        address
        tel
      }
      currentOrderItems {
        id
        name
        priceInTaxWithCurrency
        totalPriceInTaxWithCurrency
        quantity
        isPreSale
        item {
          id
          size
          itemImageSets {
            xsSqImage
          }
        }
        itemGroup {
          frontUrl
          publishedStatus
          listImageOrItemGroupImageSet {
            xsSqImage
          }
        }
        noshiId
        noshiName
        noshiNaire
        noshiMemo
        messageCardId
        displayMessageCard
        wrappingId
        displayWrapping
        reviewEditUrl
        review {
          postedAt
        }
        scheduledShippingStartAt
        formatScheduledShippingStartAt
      }
    }
    pageInfo {
      currentPage
      totalCount
      perPage
      orderBy
    }
  }
}
`

export const CreateReceiptPdfUrl = gql`
mutation($input: CreateReceiptPdfUrlInput!) {
  createReceiptPdfUrl(input: $input) {
    downloadUrl
    errors {
       attribute
       model
       message
    }
  }
}

`

export const UpdateOrderCreditCard = gql`
mutation($input: UpdateOrderCreditCardInput!) {
  updateOrderCreditCard(input: $input) {
    order {
      id
      currentOrderPayment {
        isErrorCreditCard
        payJpCardId
      }
    }
    errors {
      model
      message
      attribute
    }
  }
}
`
