<template>
  <svg width="16" height="16" viewBox="0 0 16 16" stroke="#A98948" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99702 3.25554C7.27611 2.11959 6.01278 1.625 4.69537 1.625C2.64968 1.625 0.9375 3.25696 0.9375 5.37395C0.9375 6.52854 1.47892 7.49525 2.12748 8.27119C2.71516 8.9743 3.42845 9.56362 3.98311 10.0219C4.03775 10.067 4.09086 10.1109 4.14216 10.1535L4.14294 10.1541C5.5343 11.3046 7.00821 12.8718 7.55679 13.6382C7.64398 13.7662 7.79859 13.875 8 13.875C8.20141 13.875 8.35602 13.7662 8.4432 13.6382C8.99177 12.8718 10.4657 11.3046 11.8571 10.1541L11.8578 10.1535C11.9144 10.1065 11.9733 10.058 12.034 10.0079C12.5854 9.55285 13.2905 8.97096 13.8722 8.27668C14.5215 7.50159 15.0625 6.53486 15.0625 5.37395C15.0625 3.25696 13.3503 1.625 11.3046 1.625C9.99123 1.625 8.71461 2.11743 7.99702 3.25554Z" />
  </svg>
</template>
<script>
  export default {
    name: "FavoriteSvg"
  }
</script>
